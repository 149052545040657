















import { defineComponent } from '@vue/composition-api';
import { ListPageLayout, InlineLoader } from '@/shared';
import Filters from './Filters.vue';
import Table from './Table.vue';
import sink from '@/sink';

export default defineComponent({
  components: { ListPageLayout, Filters, Table, InlineLoader },
  setup() {
    return {
      loading: sink.select('patientEngagement.loading'),
    };
  },
});
