var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"mb-4",attrs:{"must-sort":"","mobile-breakpoint":"1080","headers":_vm.headers,"items":_vm.patientEngagements,"page":_vm.pageNumber,"items-per-page":25,"server-items-length":_vm.count,"sort-by":_vm.sortCol,"sort-desc":_vm.sortDir === 'desc',"hide-default-footer":!_vm.patientEngagements.length,"footer-props":{
    itemsPerPageOptions: [25],
    showCurrentPage: true,
    showFirstLastPage: true,
  }},on:{"update:page":_vm.updatePage,"update:sort-desc":_vm.onChangeSortDirection,"update:sort-by":_vm.onChangeSortColumn},scopedSlots:_vm._u([{key:"item.patientName",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"patient-column"},[_c('router-link',{staticClass:"patient-column__name",attrs:{"to":("/patients/" + (item.patientId))}},[_vm._v(" "+_vm._s(item.patientName)+" ")]),_c('div',{staticClass:"patient-column__id"},[_c('router-link',{staticClass:"patient-column__id-header",attrs:{"to":("/patients/" + (item.patientId))}},[_vm._v(" "+_vm._s(item.patientName)+" ")]),_c('p',[_vm._v(" "+_vm._s(item.clinicPatientCode)+" ")])],1)],1)]}},{key:"item.enrollmentDate",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"program-header"},[_c('span',{staticClass:"program"},[_vm._v(" "+_vm._s(item.program)+" ")]),_c('span',{staticClass:"enrollment-date"},[_vm._v(" "+_vm._s(item.enrollmentDate)+" ")])])]}},{key:"item.lastScheduledChatDate",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"last-scheduled-chat-date-header"},[_c('span',{staticClass:"last-chat-status"},[_vm._v(" "+_vm._s(item.lastChatStatus)+" ")]),_c('span',{staticClass:"last-scheduled-chat-date"},[_vm._v(" "+_vm._s(item.lastScheduledChatDate)+" ")])])]}},{key:"item.chatsIgnored",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"chats-header"},[_c('span',{staticClass:"chats-ignored"},[_vm._v(_vm._s(item.chatsIgnored))])])]}},{key:"item.nextScheduledChatDate",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"next-scheduled-chat-date-header"},[_c('span',{staticClass:"next-scheduled-chat-date"},[_vm._v(" "+_vm._s(item.nextScheduledChatDate)+" ")])])]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.tableMessage)+" ")]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }