






















































































import { defineComponent } from '@vue/composition-api';
import {
  PatientEngagementsTableSorted,
  PatientEngagementsPageUpdated,
} from './+state';
import { PatientEngagementsSortCol, SortDir } from '@/shared';
import sink from '@/sink';

export default defineComponent({
  setup() {
    const sortCol = sink.select('patientEngagement.sort-col');
    const sortDir = sink.select('patientEngagement.sort-dir');

    const updateSort = (sortInfo: {
      col: PatientEngagementsSortCol;
      dir: SortDir;
    }) => sink.broadcast(PatientEngagementsTableSorted(sortInfo));

    const onChangeSortColumn = (col: PatientEngagementsSortCol) => {
      updateSort({ col, dir: sortDir.value });
    };

    const onChangeSortDirection = (isDesc: boolean) => {
      updateSort({ col: sortCol.value, dir: isDesc ? 'desc' : 'asc' });
    };

    return {
      onChangeSortColumn,
      onChangeSortDirection,
      updatePage: page => {
        sink.broadcast(PatientEngagementsPageUpdated({ page }));
      },
      sortCol,
      sortDir,
      patientEngagements: sink.select('patientEngagement.table'),
      count: sink.select('patientEngagement.count'),
      filtersDirty: sink.select('patientEngagement.filters-dirty'),
      pageNumber: sink.select('patientEngagement.page-number'),
      tableMessage: sink.select('patientEngagement.tableMessage'),
      headers: [
        {
          value: 'patientName',
          text: 'Patient',
          sortable: true,
        },
        {
          value: 'enrollmentDate',
          text: 'Program & Enrollment Date',
          sortable: true,
        },
        {
          value: 'lastScheduledChatDate',
          text: 'Last Chat',
          sortable: true,
        },
        { value: 'chatsIgnored', text: 'Ignored Count', sortable: true },
        {
          value: 'nextScheduledChatDate',
          text: 'Next Scheduled Chat',
          sortable: true,
        },
      ],
    };
  },
});
